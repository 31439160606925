/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import womanHomeHealthAide from '../images/heroes/woman-with-home-health-aide.jpg'
import partAFormula from '../images/icons/part-a-formula.svg'
import heart65Plus from '../images/icons/purple-65-plus.svg'
import heart65Minus from '../images/icons/purple-65-minus.svg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import CobraBanner from '../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-aet-parta']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Aetna 2023 Medicare Insurance | 855-200-5685 | Plans & Coverage',
          description:
            'Compare Aetna Medicare plans and let us help you enroll in the one that fits your healthcare needs. Call us today at 855-200-569',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/parta',
        promoCode: '120762',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'aetnamedicare',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={womanHomeHealthAide}
                  alt="elderly woman and home health aide smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna<sup>®</sup> Medicare{' '}
                  </Typography>

                  <Typography variant="h4">
                    Learn more about Medicare Part A{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                What Is Aetna Medicare Part A?
              </Typography>
              <img
                src={partAFormula}
                alt="hospital insurance + hospice coverage = Medicare Part A formula illustration"
                className="margin-bottom-md"
              />
              <Typography variant="body">
                Medicare Part A is a health insurance plan funded and
                administered by the federal government. Together with Medicare
                Part B, it makes up what’s called “Original Medicare.” Most
                people who are 65 or older and some people younger than 65 are
                eligible for Medicare Part A coverage.
              </Typography>
              <Typography variant="h5" color="primary">
                Learn more about Medicare Part A coverage today.
              </Typography>
            </>
          }
        ></VariableContent>

        <CobraBanner />

        <VariableContent className="bg-light-gray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-checklist.svg"
                alt="checklist icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                What Does Medicare Part A Cover?
              </Typography>
              <Typography variant="body">
                Your coverage may vary depending on where you live, but Medicare
                Part A coverage can be divided into four main areas:
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Inpatient Hospital Care',
                      content: (
                        <>
                          <Typography>
                            If you’re eligible for Medicare Part A, you may
                            receive coverage for a number of inpatient hospital
                            services and supplies, such as:
                          </Typography>
                          <List>
                            <ListItem>
                              In-patient medical or surgical treatment
                            </ListItem>
                            <ListItem>Semi-private room stays</ListItem>
                            <ListItem>Nursing services</ListItem>
                            <ListItem>
                              Drugs and medications that are part of your
                              inpatient treatment
                            </ListItem>
                            <ListItem>Hospital meals</ListItem>
                            <ListItem>Mental health care</ListItem>
                            <ListItem>
                              Participation in qualifying clinical research
                              studies or “demonstrations”
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                    {
                      title: 'Nursing Home Care',
                      content: (
                        <>
                          <Typography>
                            Usually, Medicare Part A covers your services at a
                            skilled nursing facility (SNF) after any
                            Medicare-covered inpatient stay at a hospital of
                            three days or more. Examples of covered services may
                            include:
                          </Typography>
                          <List>
                            <ListItem>Semi-private room</ListItem>
                            <ListItem>
                              Medically necessary rehabilitation services
                            </ListItem>
                            <ListItem>Meals</ListItem>
                            <ListItem>Dietary counseling</ListItem>
                            <ListItem>Medical social services</ListItem>
                            <ListItem>Drugs and medications</ListItem>
                            <ListItem>Medical equipment and supplies</ListItem>
                          </List>
                        </>
                      ),
                    },
                    {
                      title: 'Home Health Services',
                      content: (
                        <>
                          <Typography>
                            If you are “homebound” and your doctor determines
                            you need home health care services, Medicare Part A
                            may help pay for them. Some covered services may
                            include (but are not necessarily limited to):
                          </Typography>
                          <List>
                            <ListItem>
                              Physical or occupational therapy
                            </ListItem>
                            <ListItem>Part-time skilled nursing care</ListItem>
                            <ListItem>
                              Durable medical equipment ordered by your doctor
                            </ListItem>
                            <ListItem>Home health aide services</ListItem>
                            <ListItem>Medical social services</ListItem>
                            <ListItem>
                              Speech-language pathology services
                            </ListItem>
                          </List>
                          <Typography>
                            Keep in mind, the following services and supplies
                            are NOT covered:
                          </Typography>
                          <List>
                            <ListItem>
                              24-hour home care, meals, or housekeeping services
                              that are unrelated to your treatment
                            </ListItem>
                            <ListItem>
                              Personal care services, such as bathing, dressing,
                              or preparing meals if it’s the only care you need
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                    {
                      title: 'Hospice Care',
                      content: (
                        <>
                          <Typography>
                            If your doctor diagnoses you with a terminal illness
                            or condition and estimates that you have six or
                            fewer months to live, Medicare Part A may cover your
                            hospice care. This may include (but is not
                            necessarily limited to):
                          </Typography>
                          <List>
                            <ListItem>
                              Medical treatment (not treatment to cure your
                              condition, but to decrease symptoms or ease
                              suffering)
                            </ListItem>
                            <ListItem>Nursing care</ListItem>
                            <ListItem>Pain management services</ListItem>
                            <ListItem>Pain relief medications</ListItem>
                            <ListItem>Medical supplies</ListItem>
                            <ListItem>Durable medical equipment</ListItem>
                            <ListItem>Social services</ListItem>
                            <ListItem>Hospice aide services</ListItem>
                            <ListItem>Housekeeping services</ListItem>
                            <ListItem>
                              Physical or occupational therapy
                            </ListItem>
                            <ListItem>Meals</ListItem>
                            <ListItem>Dietary counseling</ListItem>
                            <ListItem>Short-term respite care</ListItem>
                          </List>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
              <Typography variant="body">
                To find out whether a health care service you need is covered by
                Medicare Part A, talk to a licensed agent. Your agent can tell
                you whether the service is covered by Medicare and which health
                care providers offer it in your area.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-dollar.svg"
                alt="dollar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                What Are the Costs of Medicare Part A?
              </Typography>
              <Typography variant="body">
                Most people who are eligible for Medicare Part A don’t have to
                pay a monthly premium. Some people call this “premium-free”
                Medicare Part A.
              </Typography>
              <Stack>
                <Columns className="">
                  <Column>
                    <div className="top-content">
                      <img
                        src={heart65Plus}
                        alt="65+ icon"
                        className="margin-bottom-md"
                      />
                      <Typography variant="h5" color="primary">
                        If you’re 65 or older
                      </Typography>
                      <Typography variant="body">
                        You may qualify for premium-free Medicare Part A
                        coverage if one of the following describes your
                        situation:
                      </Typography>
                      <List>
                        <ListItem>
                          You receive Social Security or Railroad Retirement
                          Board benefits
                        </ListItem>
                        <ListItem>
                          You’re eligible for Social Security or Railroad
                          Retirement Board benefits, but you haven’t filed for
                          them yet
                        </ListItem>
                        <ListItem>
                          You or your spouse worked for the government in a job
                          that was covered by Medicare
                        </ListItem>
                      </List>
                    </div>
                  </Column>
                  <Column>
                    <div className="top-content">
                      <img
                        src={heart65Minus}
                        alt="65+ icon"
                        className="margin-bottom-md"
                      />
                      <Typography variant="h5" color="primary">
                        If you’re less than 65
                      </Typography>
                      <Typography variant="body">
                        You may still qualify for premium-free Medicare Part A
                        coverage if one of the following situations applies to
                        you:
                      </Typography>
                      <List>
                        <ListItem>
                          You received Social Security or Railroad Retirement
                          Board disability benefits for 24 months
                        </ListItem>
                        <ListItem>
                          You suffer from end-stage renal disease (ESRD)
                        </ListItem>
                        <ListItem>
                          You suffer from amyotrophic lateral sclerosis (also
                          called Lou Gehrig’s disease or “ALS”)
                        </ListItem>
                      </List>
                    </div>
                  </Column>
                </Columns>
              </Stack>
              <Typography variant="body">
                If you need to purchase Medicare Part A, the maximum monthly
                premium for the year 2020 is $458. You may pay less, depending
                on how long you’ve paid Medicare taxes. Generally, if you choose
                to buy Medicare Part A coverage, you must also have Medicare
                Part B insurance and pay a Part B premium, too.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <VariableContent className="bg-light-gray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-dollar.svg"
                alt="dollar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                Is There a Medicare Part A Deductible?
              </Typography>
              <Typography variant="body">
                The deductible for Medicare Part A health insurance is $1,408
                per benefit period for inpatient hospital care.
              </Typography>
              <Typography variant="body">
                You may also have to pay coinsurance for your care. Your
                coinsurance goes according to the following scale:
              </Typography>
              <List>
                <ListItem>
                  $0 per benefit period for the first 60 days you spend in an
                  inpatient care facility
                </ListItem>
                <ListItem>
                  $352 per day if you spend 61 to 90 days in an inpatient care
                  facility during your benefit period
                </ListItem>
                <ListItem>
                  $704 per “lifetime reserve day” if you spend 91 or more days
                  at an inpatient care facility during your benefit period
                </ListItem>
              </List>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-heart.svg"
                alt="purple heart icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                Am I Eligible for Medicare Part A?
              </Typography>
              <Typography variant="body">
                Generally speaking, you’re eligible for Medicare Part A benefits
                if one or more of the following descriptions applies to you:
              </Typography>
              <List>
                <ListItem>
                  You’re 65 years or older and are a US citizen or have been a
                  US permanent legal resident for at least five years in a row
                </ListItem>
                <ListItem>You’re eligible for retirement benefits</ListItem>
                <ListItem>
                  You’re currently receiving government disability benefits
                </ListItem>
                <ListItem>
                  You suffer from end-stage renal disease (also known as
                  catastrophic kidney failure or “ESRD”)
                </ListItem>
                <ListItem>
                  You suffer from amyotrophic lateral sclerosis (also called Lou
                  Gehrig’s disease or “ALS”)
                </ListItem>
              </List>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent className="bg-light-gray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-calendar.svg"
                alt="calendar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                When Can I Enroll in Medicare Part A?
              </Typography>
              <Typography variant="body">
                If you’re enrolling in Medicare for the first time, you can
                enroll during your Initial Enrollment Period (IEP). Your Initial
                Enrollment Period begins three months before the month of your
                65th birthday and ends three months after your birth month.
              </Typography>
              <Typography variant="body">
                If you’re already on Original Medicare and want to enroll in an
                Aetna Medicare Advantage plan, you can do so during the Annual
                Enrollment Period (AEP), which starts October 15 and ends
                December 7 every year.
              </Typography>
              <Typography variant="body">
                Under some circumstances, you may be able to enroll in an Aetna
                Medicare Advantage plan during a Special Enrollment Period. For
                example, if you’ve recently moved or if you’re currently living
                in a nursing home. Talk to one of our licensed agents today to
                see if your life circumstance applies.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
